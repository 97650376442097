@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
.container {
  max-width: 1240px;
  margin: auto;
}

.icon {
  font-size: 1.5rem;
}

.bold {
  font-weight: 700;
}

.inline-link {
  color: #5f5bfe;
  transition: 300ms;
}
.inline-link:hover {
  color: #002640;
}

.gradient-wrapper {
  position: absolute;
  left: 0;
  top: -80rem;
  width: 100%;
  height: 120rem;
  transform: skewY(-12deg);
  z-index: -3;
  display: grid;
  place-items: stretch;
}

#gradient-canvas {
  width: 100%;
  height: 100%;
  --gradient-color-1: #ef008f;
  --gradient-color-2: #6ec3f4;
  --gradient-color-3: #7038ff;
  --gradient-color-4: #ffba27;
}

:root {
  font-size: 62.5%;
  --font-family: "Inter", sans-serif;
}

html {
  overflow-x: hidden;
  color-scheme: light;
}

body {
  font-size: 16px;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

ol,
ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: inherit;
  font-size: inherit;
}

img {
  width: 100%;
  height: 100%;
}

button {
  cursor: pointer;
  font-size: inherit;
  color: inherit;
  background: none;
  border: none;
}

input {
  width: 100%;
  height: 100%;
}

main {
  overflow: hidden;
}

footer {
  transform: skewY(-7deg);
}

