@media only screen and (min-width: 590px) {
    .approach {
        &-details {
            grid-template-columns: 1fr 1fr;
        }
    }
}

@media only screen and (min-width: 1024px) {
    .approach {
        translate: 0 -60%;
        &-details {
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 0;
        }
    }
}
