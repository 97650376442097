@use '../../styles/vars' as v;

.hero {
    padding: 2rem;
    padding-top: 10rem;
    max-width: v.$wrapper-max-width;
    margin: v.$wrapper-center;
    margin-top: min(35vw, 13rem);
    margin-bottom: 5rem;
    position: relative;
    display: flex;

    &-title-container {
        position: relative;
        --max-width: 75rem;
        width: min(120% - 2rem, var(--max-width));
        height: auto;
    }

    .title {
        max-width: 11ch;
        font-size: clamp(v.$hero-title-fs-min, 10vw, v.$hero-title-fs-max);
        line-height: clamp(v.$hero-title-lh-min, 10vw, v.$hero-title-lh-max);
        font-weight: v.$hero-title-fw;
        letter-spacing: clamp(v.$hero-title-ls-min, 10vw, v.$hero-title-ls-max);
        color: v.$dark-blue;
        break-inside: break;
    }

    .title-main {
        mix-blend-mode: color-burn;
        opacity: 0.9;
    }

    .title__second {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -4;
        opacity: 0.5;
    }

    .title__overlay {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.1;
        mix-blend-mode: multiply;
    }

    &-desc-container {
        margin-block: 3rem;
        --max-width: 60rem;
        width: min(100% - 2rem, var(--max-width));
        height: auto;

        .desc {
            color: v.$light-grey;
            font-size: v.$desc-fs;
            font-weight: v.$desc-fw;
            letter-spacing: v.$desc-ls;
            line-height: v.$desc-lh;
        }
    }

    &-btn-container {
        margin-block: 3rem;
        --max-width: 55rem;
        width: min(100% - 2rem, var(--max-width));
        height: auto;
        display: flex;
        gap: 1.5rem;

        button {
            padding: v.$btn-p;
            font-size: v.$btn-fs;
            font-weight: v.$btn-fw;
            letter-spacing: v.$btn-ls;
            line-height: v.$btn-lh;
            border-radius: v.$btn-br;
        }

        .btn1 {
            color: #fff;
            background-color: v.$dark-blue;
        }

        .btn2 {
            color: v.$dark-blue;
        }
    }

    &-pictures {
        display: none;
        transform: scale(1.1);

        .desktop {
            position: absolute;
            top: -25rem;
            left: -40rem;
            width: auto;
            height: auto;
            border-radius: 1.2rem;
            box-shadow: 90px 90px 150px -70px grey;
            transform: scale(0.6);
        }

        .phone {
            position: absolute;
            top: -40rem;
            left: -40rem;
            width: auto;
            height: auto;
            background-color: black;
            transform: scale(0.4);
            border-radius: 7rem;
            box-shadow: 0 0 200px -10px grey;
        }
    }
}
