@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
    font-size: 62.5%;
    --font-family: 'Inter', sans-serif;
}

html {
    overflow-x: hidden;
    color-scheme: light;
}

body {
    font-size: 16px;
    font-family: var(--font-family);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

*,
*::before,
*::after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

ol,
ul {
    list-style-type: none;
}

a {
    text-decoration: none;
    color: inherit;
    font-size: inherit;
}

img {
    width: 100%;
    height: 100%;
}

button {
    cursor: pointer;
    font-size: inherit;
    color: inherit;
    background: none;
    border: none;
}

input {
    width: 100%;
    height: 100%;
}

main {
    overflow: hidden;
}

footer {
    transform: skewY(-7deg);
}
