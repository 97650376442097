// basics --------------------
$wrapper-max-width: 1080px;
$wrapper-center: auto;

$grey-bg: #f6f9fc;
$blue-bg: #402E32;

$dark-blue: #0e2540;

$light-cyan: #D5A07D;
$light-grey: #425466;
$lighter-grey: #DFE0DF;
$light-purple: #B55906;

$head-fs: 18px;
$head-fw: 700;
$head-ls: 0.2px;
$head-lh: 28px;

$sec-title-fs-max: 38px;
$sec-title-fs-min: 34px;
$sec-title-fw: 700;
$sec-title-ls-max: -0.2px;
$sec-title-ls-min: -0.1px;
$sec-title-lh-max: 48px;
$sec-title-lh-min: 44px;

$desc-fs: 18px;
$desc-fw: 450;
$desc-ls: 0.2px;
$desc-lh: 28px;

$info-fs: 15px;
$info-fw: 600;
$info-ls: 0.2px;
$info-lh: 24px;
$info-p: 0px 32px 0px 16px;

$btn-fs: 15px;
$btn-fw: 550;
$btn-ls: 0.2px;
$btn-lh: 24px;
$btn-p: 6px 16px 6px 16px;
$btn-br: 16px;

$link-fw: 525;

// nav vars ----------------------

// hero vars ---------------------
$hero-bg-color: #ffffff;
$hero-title-fs-max: 94px;
$hero-title-fs-min: 40px;
$hero-title-fw: 700;
$hero-title-ls-max: -3.76px;
$hero-title-ls-min: -2.53px;
$hero-title-lh-max: 97.76px;
$hero-title-lh-min: 50px;

$hero-btn-color: #fff;
$hero-btn-bg-hover-color: #6c7c8c;

// unified vars ------------------------

// terminal vars -----------------------
//
//